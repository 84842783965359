<template>
	<div></div>
</template>

<script>
	export default {
		beforeMount() {
			window.location.href = this.$route.path;
		}
	}
</script>
